import { template as template_f7d8e34123a24a97a60bb4f5d563a705 } from "@ember/template-compiler";
const FKLabel = template_f7d8e34123a24a97a60bb4f5d563a705(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
