import { template as template_d0b8a5f3ce8e41b3b8ee304ec51fb432 } from "@ember/template-compiler";
import bodyClass from "discourse/helpers/body-class";
const AddTopicStatusClasses = template_d0b8a5f3ce8e41b3b8ee304ec51fb432(`
  {{#if @topic.invisible}}
    {{bodyClass "topic-status-unlisted"}}
  {{/if}}
  {{#if @topic.pinned}}
    {{bodyClass "topic-status-pinned"}}
  {{/if}}
  {{#if @topic.unpinned}}
    {{bodyClass "topic-status-unpinned"}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddTopicStatusClasses;
