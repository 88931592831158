import { template as template_07b4b4086b8947a88fc6c0f72d6ddc2e } from "@ember/template-compiler";
const FKText = template_07b4b4086b8947a88fc6c0f72d6ddc2e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
